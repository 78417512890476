@charset "UTF-8";
/* Vars
--------------------- */
/* ソースサイズ */
/* Mixin
--------------------- */
/* Media Queries */
/* Vendor Prefix */
/* Extend
--------------------- */
/* Clearfix */
/* Ellipsis */
/* PseudoTable */
/* Inner */
/* Visual */
.visual {
  margin-top: 148px;
  height: calc(100dvh - 148px);
  position: relative;
  z-index: 1;
}
@media only screen and (max-width: 1088px) {
  .visual {
    margin-top: 13.6029411765vw;
    height: calc(100dvh - 13.6029411765vw);
  }
}
@media screen and (max-width: 767px) {
  .visual {
    margin-top: 14.9333333333vw;
    height: calc(100dvh - 14.9333333333vw);
  }
}
.visual ul.visual-animate__slider {
  height: 100%;
  width: 100%;
}
.visual ul.visual-animate__slider > li {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.visual .visual-animate__bg {
  width: calc(100% + 50px);
}
.visual__txt1 {
  display: none;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  z-index: 9;
}
.visual__txt1 img {
  width: 728px;
}
@media screen and (max-width: 767px) {
  .visual__txt1 img {
    width: 69.0666666667vw;
  }
}

/* Contents */
.contents-wrap {
  background: #F9F9F9 url(../img/top/contents_bg_01.png) 50% 100% no-repeat;
  background-size: 100% auto;
}
@media screen and (max-width: 767px) {
  .contents-wrap {
    background-image: url(../img/top/contents_bg_01@sp.png);
  }
}

/* Main Contents */
.main-contents {
  margin-bottom: 80px;
  padding-left: 8px;
  padding-right: 8px;
}
@media screen and (max-width: 767px) {
  .main-contents {
    margin-bottom: 40px;
    padding-left: 6.6666666667vw;
    padding-right: 6.6666666667vw;
  }
}
.main-contents > div {
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  margin: 0 auto;
  max-width: 1088px;
}
@media screen and (max-width: 767px) {
  .main-contents > div {
    flex-direction: column;
  }
}
.main-contents__header {
  background-color: #0086CA;
  position: relative;
  text-align: center;
}
.main-contents__header::after {
  background: #fff;
  content: "";
  display: block;
  height: 100%;
  right: 0;
  position: absolute;
  top: 0;
  width: 1px;
}
@media screen and (max-width: 767px) {
  .main-contents__header::after {
    display: none;
  }
}
.main-contents__header a {
  display: flex;
  flex-direction: column;
  min-height: 160px;
  padding: 32px 0 56px;
  position: relative;
  text-decoration: none;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .main-contents__header a {
    min-height: auto;
    padding: 8.5333333333vw 0 14.9333333333vw;
  }
}
.main-contents__header a::after {
  background: url(../img/top/icom_main_contents_01.svg) 0% 0% no-repeat;
  background-size: 100% 100%;
  bottom: 32px;
  content: "";
  display: block;
  height: 8px;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0);
  width: 54px;
}
@media screen and (max-width: 767px) {
  .main-contents__header a::after {
    bottom: 8.5333333333vw;
    height: 2.1333333333vw;
    width: 14.4vw;
  }
}
.main-contents__header a:hover {
  opacity: 0.5;
}
.main-contents__header__title {
  color: #fff;
  font-size: 2.4rem;
  font-weight: 700;
  margin-bottom: 16px;
}
@media screen and (max-width: 767px) {
  .main-contents__header__title {
    font-size: 5.6vw;
    margin-bottom: 4.2666666667vw;
  }
}
.main-contents__header__txt1 {
  color: #fff;
  font-size: 1.4rem;
}
@media screen and (max-width: 767px) {
  .main-contents__header__txt1 {
    font-size: 3.7333333333vw;
  }
}
.main-contents figure {
  position: relative;
  text-align: center;
}
.main-contents figure::after {
  background: #fff;
  content: "";
  display: block;
  height: 100%;
  right: 0;
  position: absolute;
  top: 0;
  width: 1px;
}
@media screen and (max-width: 767px) {
  .main-contents figure::after {
    display: none;
  }
}
.main-contents figure img {
  display: block;
  width: 100%;
}
.main-contents__nav {
  padding: 24px 8.8397790055% 16px;
}
@media screen and (max-width: 767px) {
  .main-contents__nav {
    padding: 8.5333333333vw 8.5333333333vw 4.2666666667vw;
  }
}
.main-contents__nav dl dt {
  background-color: rgba(22, 134, 202, 0.1);
  color: #354052;
  font-size: 1.4rem;
  line-height: 1.7142857143;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .main-contents__nav dl dt {
    font-size: 3.7333333333vw;
  }
}
.main-contents__nav dl dd ul {
  list-style-type: disc;
  margin-bottom: 10px;
  padding-left: 1.5em;
}
@media screen and (max-width: 767px) {
  .main-contents__nav dl dd ul {
    margin-bottom: 1.0666666667vw;
  }
}
.main-contents__nav dl dd ul li {
  position: relative;
}
.main-contents__nav dl dd ul li::after {
  background: url(../img/top/line_main_contents_01.svg) 0% 100% repeat-x;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: -1.5em;
  position: absolute;
  width: 100%;
}
.main-contents__nav dl dd ul li:last-child::after {
  display: none;
}
.main-contents__nav dl dd ul li a {
  color: #354052;
  display: block;
  font-size: 1.4rem;
  line-height: 1.2857142857;
  padding-bottom: 11px;
  padding-top: 11px;
  text-decoration: none;
}
@media screen and (max-width: 767px) {
  .main-contents__nav dl dd ul li a {
    font-size: 3.7333333333vw;
    padding-bottom: 2.9333333333vw;
    padding-top: 2.9333333333vw;
  }
}
.main-contents__nav dl dd ul li a:hover {
  text-decoration: underline;
}
.main-contents__item {
  position: relative;
  width: 33.33%;
}
.main-contents__item::after {
  background: rgba(204, 204, 204, 0.5);
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
}
@media screen and (max-width: 767px) {
  .main-contents__item::after {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .main-contents__item {
    width: 100%;
  }
}
.main-contents__item:last-child {
  margin-bottom: 0;
}
.main-contents__item:last-child::after,
.main-contents__item:last-child .main-contents__header::after,
.main-contents__item:last-child figure::after {
  display: none;
}

/* News */
.news {
  margin-bottom: 80px;
  margin-top: 80px;
  padding: 0 8px;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 767px) {
  .news {
    margin-bottom: 40px;
    margin-top: 40px;
    padding: 0 6.6666666667vw;
  }
}
.news > div {
  background: #fff;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
  display: flex;
  margin: 0 auto;
  max-width: 1088px;
  padding: 32px 40px 40px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .news > div {
    flex-direction: column;
    padding: 6.4vw;
  }
}
.news__hd {
  font-family: "Noto Serif JP", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
}
@media screen and (max-width: 767px) {
  .news__hd {
    font-size: 2rem;
  }
}
.news__col {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 40px;
  padding-bottom: 6px;
  width: 95px;
}
@media screen and (max-width: 767px) {
  .news__col {
    flex-direction: row;
    margin-right: 0;
    padding-bottom: 24px;
    width: 100%;
  }
}
.news .news-archive {
  margin-bottom: 0;
  width: 100%;
}
.news__btn {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .news__btn {
    margin-top: 0;
  }
}
.news__btn a {
  background: #fff;
  border: 1px solid #0086CA;
  border-radius: 12px;
  color: #0086CA;
  display: block;
  font-size: 1.4rem;
  text-align: center;
  text-decoration: none;
  line-height: 22px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .news__btn a {
    width: 95px;
  }
}
.news__btn a span {
  display: inline-block;
  padding-left: 12px;
  position: relative;
}
.news__btn a span::after {
  content: "\f0da";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  left: 0;
  line-height: 1;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
.news__btn a:hover {
  opacity: 0.5;
}

/* Sub Contents1 */
.sub-contents1 {
  padding: 0 8px 56px;
}
@media screen and (max-width: 1088px) {
  .sub-contents1 {
    padding-bottom: calc(80px - 2.2058823529vw);
  }
}
@media screen and (max-width: 767px) {
  .sub-contents1 {
    padding: 0 6.6666666667vw 40px;
  }
}
.sub-contents1 ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1088px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .sub-contents1 ul {
    flex-direction: column;
  }
}
.sub-contents1 li {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.16);
  margin-bottom: 2.2058823529%;
  margin-right: 3.6764705882%;
  position: relative;
  width: 30.8823529412%;
}
@media screen and (max-width: 767px) {
  .sub-contents1 li {
    margin-bottom: 16px;
    width: 100%;
  }
  .sub-contents1 li:last-child {
    margin-bottom: 0;
  }
}
.sub-contents1 li:nth-child(3n) {
  margin-right: 0;
}
.sub-contents1 li a:hover {
  opacity: 0.5;
}
.sub-contents1 img {
  width: 100%;
}
.sub-contents1__txt1 {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  left: 0;
  overflow: hidden;
  padding: 0 8px;
  position: absolute;
  width: 100%;
}
@media screen and (max-width: 1088px) {
  .sub-contents1__txt1 {
    padding: 0 0.7352941176vw;
  }
}
@media screen and (max-width: 767px) {
  .sub-contents1__txt1 {
    padding: 0 2.1333333333vw;
  }
}
.sub-contents1__txt1 span:nth-child(1) {
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
  font-family: "Noto Serif JP", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  display: block;
  line-height: 32px;
  position: relative;
  z-index: 3;
}
@media screen and (max-width: 1088px) {
  .sub-contents1__txt1 span:nth-child(1) {
    font-size: 1.8382352941vw;
    line-height: 2.9411764706vw;
  }
}
@media screen and (max-width: 767px) {
  .sub-contents1__txt1 span:nth-child(1) {
    font-size: 5.3333333333vw;
    line-height: 8.5333333333vw;
  }
}
.sub-contents1__txt1 span:nth-child(2) {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  display: block;
  line-height: 1;
  position: relative;
  z-index: 3;
}
@media screen and (max-width: 1088px) {
  .sub-contents1__txt1 span:nth-child(2) {
    font-size: 1.1029411765vw;
  }
}
@media screen and (max-width: 767px) {
  .sub-contents1__txt1 span:nth-child(2) {
    font-size: 2.6666666667vw;
  }
}
.sub-contents1__txt1::after {
  background-color: rgba(0, 0, 0, 0.4);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

/* Sub Contents2 */
.sub-contents2 {
  padding: 0 8px 56px;
}
@media screen and (max-width: 1088px) {
  .sub-contents2 {
    padding-bottom: calc(80px - 2.2058823529vw);
  }
}
@media screen and (max-width: 767px) {
  .sub-contents2 {
    padding: 0 6.6666666667vw 40px;
  }
}
.sub-contents2 ul {
  border-top: 1px solid #E5E5E5;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1088px;
  padding-top: 40px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .sub-contents2 ul {
    flex-direction: column;
  }
}
.sub-contents2 li {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.05);
  margin-bottom: 2.2058823529%;
  margin-right: 3.6764705882%;
  position: relative;
  width: 30.8823529412%;
}
@media screen and (max-width: 767px) {
  .sub-contents2 li {
    margin-bottom: 16px;
    width: 100%;
  }
  .sub-contents2 li:last-child {
    margin-bottom: 0;
  }
}
.sub-contents2 li:nth-child(3n) {
  margin-right: 0;
}
.sub-contents2 li a:hover {
  opacity: 0.5;
}
.sub-contents2 img {
  width: 100%;
}

/* Related site */
.related-site {
  background-color: #F9F9F9;
  background-size: 100% auto;
  padding: 48px 8px 56px;
  position: relative;
}
@media screen and (max-width: 1088px) {
  .related-site {
    padding: 4.4117647059vw 0.7352941176vw 5.1470588235vw;
  }
}
@media screen and (max-width: 767px) {
  .related-site {
    padding: 6.4vw 6.6666666667vw 10.6666666667vw;
  }
}
.related-site::after {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0) 100%);
  content: "";
  display: block;
  height: 170px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
@media screen and (max-width: 1088px) {
  .related-site::after {
    height: 15.625vw;
  }
}
@media screen and (max-width: 767px) {
  .related-site::after {
    height: 45.3333333333vw;
  }
}
.related-site > div {
  margin: 0 auto;
  max-width: 1088px;
  width: 100%;
}
.related-site__header {
  align-items: center;
  display: flex;
  font-family: "Noto Serif JP", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  justify-content: center;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .related-site__header {
    flex-direction: column;
  }
}
.related-site__header__col:nth-child(1) {
  box-shadow: 1px 0 0 #ccc, 2px 0 0 #fff;
  margin-right: 28px;
  padding-right: 28px;
}
@media screen and (max-width: 1088px) {
  .related-site__header__col:nth-child(1) {
    margin-right: 2.5735294118vw;
    padding-right: 2.5735294118vw;
  }
}
@media screen and (max-width: 767px) {
  .related-site__header__col:nth-child(1) {
    box-shadow: 0 1px 0 #ccc, 0 2px 0 #fff;
    margin-right: 0;
    padding-right: 0;
    padding-bottom: 2.1333333333vw;
    text-align: center;
    width: 100%;
  }
}
.related-site__header__col:nth-child(1) a {
  align-items: center;
  color: #354052;
  display: flex;
  font-size: 2rem;
  font-weight: 700;
  text-decoration: none;
}
@media screen and (max-width: 1088px) {
  .related-site__header__col:nth-child(1) a {
    font-size: 1.8382352941vw;
  }
}
@media screen and (max-width: 767px) {
  .related-site__header__col:nth-child(1) a {
    font-size: 4.2666666667vw;
    justify-content: center;
    width: 100%;
  }
}
.related-site__header__col:nth-child(1) a span {
  display: inline-block;
  margin-left: 16px;
  padding-left: 13px;
  position: relative;
}
@media screen and (max-width: 1088px) {
  .related-site__header__col:nth-child(1) a span {
    margin-left: 1.4705882353vw;
    padding-left: 1.1948529412vw;
  }
}
@media screen and (max-width: 767px) {
  .related-site__header__col:nth-child(1) a span {
    margin-left: 3.4666666667vw;
    padding-left: 2.6666666667vw;
  }
}
.related-site__header__col:nth-child(1) a span::after {
  content: "\f0da";
  font-size: 1.4rem;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  left: 0;
  line-height: 1;
  position: absolute;
  transform: translate(0, -50%);
  top: 50%;
}
@media screen and (max-width: 1088px) {
  .related-site__header__col:nth-child(1) a span::after {
    font-size: 1.2867647059vw;
  }
}
@media screen and (max-width: 767px) {
  .related-site__header__col:nth-child(1) a span::after {
    font-size: 2.9333333333vw;
  }
}
.related-site__header__col:nth-child(1) a:hover {
  opacity: 0.5;
}
@media screen and (max-width: 767px) {
  .related-site__header__col:nth-child(2) {
    padding-top: 3.7333333333vw;
  }
}
.related-site__header__col:nth-child(2) span {
  display: block;
}
@media screen and (max-width: 767px) {
  .related-site__header__col:nth-child(2) span {
    text-align: center;
  }
}
.related-site__header__col:nth-child(2) span:nth-child(1) {
  font-size: 1.4rem;
}
@media screen and (max-width: 1088px) {
  .related-site__header__col:nth-child(2) span:nth-child(1) {
    font-size: 1.2867647059vw;
  }
}
@media screen and (max-width: 767px) {
  .related-site__header__col:nth-child(2) span:nth-child(1) {
    font-size: 2.9333333333vw;
  }
}
.related-site__header__col:nth-child(2) span:nth-child(2) {
  font-size: 2rem;
  font-weight: 700;
}
@media screen and (max-width: 1088px) {
  .related-site__header__col:nth-child(2) span:nth-child(2) {
    font-size: 1.8382352941vw;
  }
}
@media screen and (max-width: 767px) {
  .related-site__header__col:nth-child(2) span:nth-child(2) {
    font-size: 4.2666666667vw;
  }
}
.related-site__cols {
  display: flex;
  margin: 0 auto;
  max-width: 1088px;
  padding-top: 56px;
  width: 100%;
}
@media screen and (max-width: 1088px) {
  .related-site__cols {
    padding-top: 5.1470588235vw;
  }
}
@media screen and (max-width: 767px) {
  .related-site__cols {
    box-shadow: inset 0 -1px 0 #ccc, inset 0 -2px 0 #fff;
    flex-direction: column;
    padding-top: 10.6666666667vw;
  }
}
.related-site__cols__col {
  margin-right: 3.6764705882%;
  width: 30.8823529412%;
}
@media screen and (max-width: 767px) {
  .related-site__cols__col {
    width: 100%;
  }
}
.related-site__cols__col:last-child {
  margin-right: 0;
}
.related-site__cols__hd {
  background-color: #E5E5E5;
  font-family: "Noto Serif JP", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
}
@media screen and (max-width: 1088px) {
  .related-site__cols__hd {
    font-size: 1.2867647059vw;
    line-height: 2.9411764706vw;
  }
}
@media screen and (max-width: 767px) {
  .related-site__cols__hd {
    font-size: 3.7333333333vw;
    line-height: 8.5333333333vw;
  }
}
.related-site__cols li {
  border-bottom: 1px solid #ccc;
}
@media screen and (max-width: 767px) {
  .related-site__cols li {
    border-bottom: 0;
    box-shadow: 0 1px 0 #ccc, 0 2px 0 #fff;
  }
  .related-site__cols li:last-child {
    box-shadow: none;
  }
}
.related-site__cols li a {
  align-items: center;
  color: #354052;
  display: flex;
  font-family: "Noto Serif JP", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-size: 1.8rem;
  line-height: 1.3333333333;
  padding-bottom: 16px;
  padding-top: 16px;
  text-decoration: none;
}
@media screen and (max-width: 1088px) {
  .related-site__cols li a {
    font-size: 1.6544117647vw;
    padding-bottom: 1.4705882353vw;
    padding-top: 1.4705882353vw;
  }
}
@media screen and (max-width: 767px) {
  .related-site__cols li a {
    font-size: 3.7333333333vw;
    padding-bottom: 4.2666666667vw;
    padding-top: 4.2666666667vw;
  }
}
.related-site__cols li a span {
  display: inline-block;
  margin-left: 16px;
  padding-left: 13px;
  position: relative;
}
@media screen and (max-width: 1088px) {
  .related-site__cols li a span {
    margin-left: 1.4705882353vw;
    padding-left: 1.1948529412vw;
  }
}
@media screen and (max-width: 767px) {
  .related-site__cols li a span {
    margin-left: 4.2666666667vw;
    padding-left: 2.6666666667vw;
  }
}
.related-site__cols li a span::after {
  content: "\f0da";
  font-size: 1.4rem;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  left: 0;
  line-height: 1;
  position: absolute;
  transform: translate(0, -50%);
  top: 50%;
}
@media screen and (max-width: 1088px) {
  .related-site__cols li a span::after {
    font-size: 1.2867647059vw;
  }
}
@media screen and (max-width: 767px) {
  .related-site__cols li a span::after {
    font-size: 2.9333333333vw;
  }
}
.related-site__cols li figure img {
  width: 64px;
}
@media screen and (max-width: 1088px) {
  .related-site__cols li figure img {
    width: 5.8823529412vw;
  }
}
@media screen and (max-width: 767px) {
  .related-site__cols li figure img {
    width: 12.8vw;
  }
}

.footer::after {
  display: none;
}