@charset "utf-8";
@import "mixin";

/* Visual */
.visual {
	margin-top: 148px;
	height: calc(100dvh - 148px);
	position: relative;
	z-index: 1;
	@media only screen and (max-width: 1088px) {
		margin-top: calc(148 / 1088 * 100vw);
		height: calc(100dvh - calc(148 / 1088 * 100vw));
	}
	@include max-screen($breakpoint-sp) {
		margin-top: calc(56 / $sp-source-w * 100vw);
		height: calc(100dvh - calc(56 / $sp-source-w * 100vw));
	}
	ul.visual-animate__slider {
		height: 100%;
		width: 100%;
		> li {
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}
	}
	.visual-animate__bg {
		width: calc(100% + 50px);
	}
	&__txt1 {
		display: none;
		left: 50%;
		position: absolute;
		transform: translate(-50%, -50%);
		top: 50%;
		z-index: 9;
		img {
			width: 728px;
			@include max-screen($breakpoint-sp) {
				width: calc(259 / $sp-source-w * 100vw);
			}
		}
	}
}

/* Contents */
.contents-wrap {
	background: #F9F9F9 url(../img/top/contents_bg_01.png) 50% 100% no-repeat;
	background-size: 100% auto;
	@include max-screen($breakpoint-sp) {
		background-image: url(../img/top/contents_bg_01@sp.png);
	}
}

/* Main Contents */
.main-contents {
	margin-bottom: 80px;
	padding-left: 8px;
	padding-right: 8px;
	@include max-screen($breakpoint-sp) {
		margin-bottom: 40px;
		padding-left: calc(25 / $sp-source-w * 100vw);
		padding-right: calc(25 / $sp-source-w * 100vw);
	}
	> div {
		box-shadow: 0 0 16px 0 rgba($color: #000000, $alpha: .05);
		display: flex;
		margin: 0 auto;
		max-width: 1088px;
		@include max-screen($breakpoint-sp) {
			flex-direction: column;
		}
	}
	&__header {
		background-color: #0086CA;
		position: relative;
		text-align: center;
		&::after {
			background: #fff;
			content: '';
			display: block;
			height: 100%;
			right: 0;
			position: absolute;
			top: 0;
			width: 1px;
			@media screen and (max-width: 767px) {
				display: none;
			}
		}
		a {
			display: flex;
			flex-direction: column;
			min-height: 160px;
			padding: 32px 0 56px;
			position: relative;
			text-decoration: none;
			width: 100%;
			@media screen and (max-width: 767px) {
				min-height: auto;
				padding: calc(32 / $sp-source-w * 100vw) 0 calc(56 / $sp-source-w * 100vw);
			}
			&::after {
				background: url(../img/top/icom_main_contents_01.svg) 0% 0% no-repeat;
				background-size: 100% 100%;
				bottom: 32px;
				content: '';
				display: block;
				height: 8px;
				left: 50%;
				position: absolute;
				transform: translate(-50%, 0);
				width: 54px;
				@media screen and (max-width: 767px) {
					bottom: calc(32 / $sp-source-w * 100vw);
					height: calc(8 / $sp-source-w * 100vw);
					width: calc(54 / $sp-source-w * 100vw);
				}
			}
			&:hover {
				opacity: 0.5;
			}
		}
		&__title {
			color: #fff;
			font-size: 2.4rem;
			font-weight: 700;
			margin-bottom: 16px;
			@media screen and (max-width: 767px) {
				font-size: calc(21 / $sp-source-w * 100vw);
				margin-bottom: calc(16 / $sp-source-w * 100vw);
			}
		}
		&__txt1 {
			color: #fff;
			font-size: 1.4rem;
			@media screen and (max-width: 767px) {
				font-size: calc(14 / $sp-source-w * 100vw);
			}
		}
	}
	figure {
		position: relative;
		text-align: center;
		&::after {
			background: #fff;
			content: '';
			display: block;
			height: 100%;
			right: 0;
			position: absolute;
			top: 0;
			width: 1px;
			@media screen and (max-width: 767px) {
				display: none;
			}
		}
		img {
			display: block;
			width: 100%;
		}
	}
	&__nav {
		padding: 24px calc(32 / 362 * 100%) 16px;
		@media screen and (max-width: 767px) {
			padding: calc(32 / $sp-source-w * 100vw) calc(32 / $sp-source-w * 100vw) calc(16 / $sp-source-w * 100vw);
		}
		dl {
			dt {
				background-color: rgba($color: #1686CA, $alpha: .1);
				color: $main-font-color;
				font-size: 1.4rem;
				line-height: calc(24 / 14);
				text-align: center;
				@media screen and (max-width: 767px) {
					font-size: calc(14 / $sp-source-w * 100vw);
				}
			}
			dd {
				ul {
					list-style-type: disc;
					margin-bottom: 10px;
					padding-left: 1.5em;
					@media screen and (max-width: 767px) {
						margin-bottom: calc(4 / $sp-source-w * 100vw);
					}
					li {
						position: relative;
						&::after {
							background: url(../img/top/line_main_contents_01.svg) 0% 100% repeat-x;
							bottom: 0;
							content: '';
							display: block;
							height: 1px;
							left: -1.5em;
							position: absolute;
							width: 100%;
						}
						&:last-child {
							&::after {
								display: none;
							}
						}
						a {
							color: $main-font-color;
							display: block;
							font-size: 1.4rem;
							line-height: calc(18 / 14);
							padding-bottom: 11px;
							padding-top: 11px;
							text-decoration: none;
							@media screen and (max-width: 767px) {
								font-size: calc(14 / $sp-source-w * 100vw);
								padding-bottom: calc(11 / $sp-source-w * 100vw);
								padding-top: calc(11 / $sp-source-w * 100vw);
							}
							&:hover {
								text-decoration: underline;
							}
						}
					}
				}
			}
		}
	}
	&__item {
		position: relative;
		width: 33.33%;
		&::after {
			background: rgba($color: #CCCCCC, $alpha: .5);
			content: '';
			display: block;
			height: 100%;
			position: absolute;
			right: 0;
			top: 0;
			width: 1px;
			@include max-screen($breakpoint-sp) {
				display: none;
			}
		}
		@include max-screen($breakpoint-sp) {
			width: 100%;
		}
		&:last-child {
			margin-bottom: 0;
			&,
			.main-contents__header,
			figure {
				&::after {
					display: none;
				}
			}
		}
	}
}

/* News */
.news {
	margin-bottom: 80px;
	margin-top: 80px;
	padding: 0 8px;
	position: relative;
	z-index: 2;
	@include max-screen($breakpoint-sp) {
		margin-bottom: 40px;
		margin-top: 40px;
		padding: 0 calc(25 / $sp-source-w * 100vw);
	}
	> div {
		background: #fff;
		box-shadow: 0 0 16px rgba($color: #000000, $alpha: .05);
		display: flex;
		margin: 0 auto;
		max-width: 1088px;
		padding: 32px 40px 40px;
		width: 100%;
		@include max-screen($breakpoint-sp) {
			flex-direction: column;
			padding: calc(24 / $sp-source-w * 100vw);
		}
	}
	&__hd {
		font-family: $mincho;
		font-size: 2.4rem;
		font-weight: 700;
		line-height: 1;
		text-align: center;
		white-space: nowrap;
		@include max-screen($breakpoint-sp) {
			font-size: 2rem;
		}
	}
	&__col {
		display: flex;
		flex-shrink: 0;
		flex-direction: column;
		justify-content: space-between;
		margin-right: 40px;
		padding-bottom: 6px;
		width: 95px;
		@include max-screen($breakpoint-sp) {
			flex-direction: row;
			margin-right: 0;
			padding-bottom: 24px;
			width: 100%;
		}
	}
	.news-archive {
		margin-bottom: 0;
		width: 100%;
	}
	&__btn {
		margin-top: 20px;
		@include max-screen($breakpoint-sp) {
			margin-top: 0;
		}
		a {
			background: #fff;
			border: 1px solid #0086CA;
			border-radius: 12px;
			color: #0086CA;
			display: block;
			font-size: 1.4rem;
			text-align: center;
			text-decoration: none;
			line-height: 22px;
			width: 100%;
			@include max-screen($breakpoint-sp) {
				width: 95px;
			}
			span {
				display: inline-block;
				padding-left: 12px;
				position: relative;
				&::after {
					content: '\f0da';
					font-family: $awesome;
					font-weight: 900;
					left: 0;
					line-height: 1;
					position: absolute;
					top: 50%;
					transform: translate(0, -50%);
				}
			}
			&:hover {
				opacity: 0.5;
			}
		}
	}
}

/* Sub Contents1 */
.sub-contents1 {
	padding: 0 8px calc(80px - 24px);
	@media screen and (max-width: 1088px) {
		padding-bottom: calc(80px - 24 / 1088 * 100vw);
	}
	@include max-screen($breakpoint-sp) {
		padding: 0 calc(25 / $sp-source-w * 100vw) 40px;
	}
	ul {
		display: flex;
		flex-wrap: wrap;
		margin: 0 auto;
		max-width: 1088px;
		width: 100%;
		@include max-screen($breakpoint-sp) {
			flex-direction: column;
		}
	}
	li {
		box-shadow: 0 8px 16px rgba($color: #000000, $alpha: .16);
		margin-bottom: calc(24 / 1088 * 100%);
		margin-right: calc(40 / 1088 * 100%);
		position: relative;
		width: calc(336 / 1088 * 100%);
		@include max-screen($breakpoint-sp) {
			margin-bottom: 16px;
			width: 100%;
			&:last-child {
				margin-bottom: 0;
			}
		}
		&:nth-child(3n) {
			margin-right: 0;
		}
		a {
			&:hover {
				opacity: .5;
			}
		}
	}
	img {
		width: 100%;
	}
	&__txt1 {
		align-items: center;
		bottom: 0;
		display: flex;
		justify-content: space-between;
		left: 0;
		overflow: hidden;
		padding: 0 8px;
		position: absolute;
		width: 100%;
		@media screen and (max-width: 1088px) {
			padding: 0 calc(8 / 1088 * 100vw);
		}
		@include max-screen($breakpoint-sp) {
			padding: 0 calc(8 / $sp-source-w * 100vw);
		}
		span {
			&:nth-child(1) {
				color: #fff;
				font-size: 2rem;
				font-weight: 700;
				font-family: $mincho;
				display: block;
				line-height: 32px;
				position: relative;
				z-index: 3;
				@media screen and (max-width: 1088px) {
					font-size: calc(20 / 1088 * 100vw);
					line-height: calc(32 / 1088 * 100vw);
				}
				@include max-screen($breakpoint-sp) {
					font-size: calc(20 / $sp-source-w * 100vw);
					line-height: calc(32 / $sp-source-w * 100vw);
				}
			}
			&:nth-child(2) {
				color: #fff;
				font-size: 1.2rem;
				font-weight: 500;
				display: block;
				line-height: 1;
				position: relative;
				z-index: 3;
				@media screen and (max-width: 1088px) {
					font-size: calc(12 / 1088 * 100vw);
				}
				@media screen and (max-width: 767px) {
					font-size: calc(10 / $sp-source-w * 100vw);
				}
			}
		}
		&::after {
			background-color: rgba($color: #000000, $alpha: .4);
			content: '';
			display: block;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 2;
		}
	}
}

/* Sub Contents2 */
.sub-contents2 {
	padding: 0 8px calc(80px - 24px);
	@media screen and (max-width: 1088px) {
		padding-bottom: calc(80px - 24 / 1088 * 100vw);
	}
	@include max-screen($breakpoint-sp) {
		padding: 0 calc(25 / $sp-source-w * 100vw) 40px;
	}
	ul {
		border-top: 1px solid $bg-color;
		display: flex;
		flex-wrap: wrap;
		margin: 0 auto;
		max-width: 1088px;
		padding-top: 40px;
		width: 100%;
		@include max-screen($breakpoint-sp) {
			flex-direction: column;
		}
	}
	li {
		box-shadow: 0 8px 16px rgba($color: #000000, $alpha: .05);
		margin-bottom: calc(24 / 1088 * 100%);
		margin-right: calc(40 / 1088 * 100%);
		position: relative;
		width: calc(336 / 1088 * 100%);
		@include max-screen($breakpoint-sp) {
			margin-bottom: 16px;
			width: 100%;
			&:last-child {
				margin-bottom: 0;
			}
		}
		&:nth-child(3n) {
			margin-right: 0;
		}
		a {
			&:hover {
				opacity: .5;
			}
		}
	}
	img {
		width: 100%;
	}
}

/* Related site */
.related-site {
	background-color: #F9F9F9;
	background-size: 100% auto;
	padding: 48px 8px 56px;
	position: relative;
	@media screen and (max-width: 1088px) {
		padding: calc(48 / 1088 * 100vw) calc(8 / 1088 * 100vw) calc(56 / 1088 * 100vw);
	}
	@include max-screen($breakpoint-sp) {
		padding: calc(24 / $sp-source-w * 100vw) calc(25 / $sp-source-w * 100vw) calc(40 / $sp-source-w * 100vw);
	}
	&::after {
		background: linear-gradient(to bottom, rgba($color: #000000, $alpha: .04) 0%, rgba($color: #000000, $alpha: 0) 100%);
		content: '';
		display: block;
		height: 170px;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		@media screen and (max-width: 1088px) {
			height: calc(170 / 1088 * 100vw);
		}
		@include max-screen($breakpoint-sp) {
			height: calc(170 / 375 * 100vw);
		}
	}
	> div {
		margin: 0 auto;
		max-width: 1088px;
		width: 100%;
	}
	&__header {
		align-items: center;
		display: flex;
		font-family: $mincho;
		justify-content: center;
		position: relative;
		z-index: 1;
		@include max-screen($breakpoint-sp) {
			flex-direction: column;
		}
		&__col {
			&:nth-child(1) {
				box-shadow: 1px 0 0 #ccc, 2px 0 0 #fff;
				margin-right: 28px;
				padding-right: 28px;
				@media screen and (max-width: 1088px) {
					margin-right: calc(28 / 1088 * 100vw);
					padding-right: calc(28 / 1088 * 100vw);
				}
				@include max-screen($breakpoint-sp) {
					box-shadow: 0 1px 0 #ccc, 0 2px 0 #fff;
					margin-right: 0;
					padding-right: 0;
					padding-bottom: calc(8 / $sp-source-w * 100vw);
					text-align: center;
					width: 100%;
				}
				a {
					align-items: center;
					color: $main-font-color;
					display: flex;
					font-size: 2rem;
					font-weight: 700;
					text-decoration: none;
					@media screen and (max-width: 1088px) {
						font-size: calc(20 / 1088 * 100vw);
					}
					@include max-screen($breakpoint-sp) {
						font-size: calc(16 / $sp-source-w * 100vw);
						justify-content: center;
						width: 100%;
					}
					span {
						display: inline-block;
						margin-left: 16px;
						padding-left: 13px;
						position: relative;
						@media screen and (max-width: 1088px) {
							margin-left: calc(16 / 1088 * 100vw);
							padding-left: calc(13 / 1088 * 100vw);
						}
						@include max-screen($breakpoint-sp) {
							margin-left: calc(13 / $sp-source-w * 100vw);
							padding-left: calc(10 / $sp-source-w * 100vw);
						}
						&::after {
							content: "\f0da";
							font-size: 1.4rem;
							font-family: $awesome;
							font-weight: 900;
							left: 0;
							line-height: 1;
							position: absolute;
							transform: translate(0, -50%);
							top: 50%;
							@media screen and (max-width: 1088px) {
								font-size: calc(14 / 1088 * 100vw);
							}
							@include max-screen($breakpoint-sp) {
								font-size: calc(11 / $sp-source-w * 100vw);
							}
						}
					}
					&:hover {
						opacity: .5;
					}
				}
			}
			&:nth-child(2) {
				@include max-screen($breakpoint-sp) {
					padding-top: calc(14 / $sp-source-w * 100vw);
				}
				span {
					display: block;
					@include max-screen($breakpoint-sp) {
						text-align: center;
					}
					&:nth-child(1) {
						font-size: 1.4rem;
						@media screen and (max-width: 1088px) {
							font-size: calc(14 / 1088 * 100vw);
						}
						@include max-screen($breakpoint-sp) {
							font-size: calc(11 / $sp-source-w * 100vw);
						}
					}
					&:nth-child(2) {
						font-size: 2rem;
						font-weight: 700;
						@media screen and (max-width: 1088px) {
							font-size: calc(20 / 1088 * 100vw);
						}
						@include max-screen($breakpoint-sp) {
							font-size: calc(16 / $sp-source-w * 100vw);
						}
					}
				}
			}
		}
	}
	&__cols {
		display: flex;
		margin: 0 auto;
		max-width: 1088px;
		padding-top: 56px;
		width: 100%;
		@media screen and (max-width: 1088px) {
			padding-top: calc(56 / 1088 * 100vw);
		}
		@include max-screen($breakpoint-sp) {
			box-shadow: inset 0 -1px 0 #ccc, inset 0 -2px 0 #fff;
			flex-direction: column;
			padding-top: calc(40 / $sp-source-w * 100vw);
		}
		&__col {
			margin-right: calc(40 / 1088 * 100%);
			width: calc(336 / 1088 * 100%);
			@include max-screen($breakpoint-sp) {
				width: 100%;
			}
			&:last-child {
				margin-right: 0;
			}
		}
		&__hd {
			background-color: $bg-color;
			font-family: $mincho;
			font-size: 1.4rem;
			font-weight: 700;
			line-height: 32px;
			text-align: center;
			@media screen and (max-width: 1088px) {
				font-size: calc(14 / 1088 * 100vw);
				line-height: calc(32 / 1088 * 100vw);
			}
			@include max-screen($breakpoint-sp) {
				font-size: calc(14 / $sp-source-w * 100vw);
				line-height: calc(32 / $sp-source-w * 100vw);
			}
		}
		li {
			border-bottom: 1px solid #ccc;
			@include max-screen($breakpoint-sp) {
				border-bottom: 0;
				box-shadow: 0 1px 0 #ccc, 0 2px 0 #fff;
				&:last-child {
					box-shadow: none;
				}
			}
			a {
				align-items: center;
				color: $main-font-color;
				display: flex;
				font-family: $mincho;
				font-size: 1.8rem;
				line-height: calc(24 / 18);
				padding-bottom: 16px;
				padding-top: 16px;
				text-decoration: none;
				@media screen and (max-width: 1088px) {
					font-size: calc(18 / 1088 * 100vw);
					padding-bottom: calc(16 / 1088 * 100vw);
					padding-top: calc(16 / 1088 * 100vw);
				}
				@include max-screen($breakpoint-sp) {
					font-size: calc(14 / $sp-source-w * 100vw);
					padding-bottom: calc(16 / $sp-source-w * 100vw);
					padding-top: calc(16 / $sp-source-w * 100vw);
				}
				span {
					display: inline-block;
					margin-left: 16px;
					padding-left: 13px;
					position: relative;
					@media screen and (max-width: 1088px) {
						margin-left: calc(16 / 1088 * 100vw);
						padding-left: calc(13 / 1088 * 100vw);
					}
					@include max-screen($breakpoint-sp) {
						margin-left: calc(16 / $sp-source-w * 100vw);
						padding-left: calc(10 / $sp-source-w * 100vw);
					}
					&::after {
						content: "\f0da";
						font-size: 1.4rem;
						font-family: $awesome;
						font-weight: 900;
						left: 0;
						line-height: 1;
						position: absolute;
						transform: translate(0, -50%);
						top: 50%;
						@media screen and (max-width: 1088px) {
							font-size: calc(14 / 1088 * 100vw);
						}
						@include max-screen($breakpoint-sp) {
							font-size: calc(11 / $sp-source-w * 100vw);
						}
					}
				}
			}
			figure {
				img {
					width: 64px;
					@media screen and (max-width: 1088px) {
						width: calc(64 / 1088 * 100vw);
					}
					@include max-screen($breakpoint-sp) {
						width: calc(48 / $sp-source-w * 100vw);
					}
				}
			}
		}
	}
}


.footer {
	&::after {
		display: none;
	}
}