@charset "utf-8";


/* Vars
--------------------- */

$breakpoint-tb: 1023;
$breakpoint-sp: 767;

// メインカラー
$main-color: #0086CA;

// サブカラー
$sub-color: #116B7A;

// 一覧を見るボタンのボーダーカラー（背景は$main-color）
$btn-border-color: #DE9043;

// 薄いカラー
$bg-color: #E5E5E5;

// .border-none th
$bg-color2: #f3f5e5;

// サイトマップ背景カラー
$local-nav-bg-color: #FCF7ED;

// お問い合わせボタンカラー
$contact-btn-bg-color: #0086CA;

// お問い合わせボタンボーダーカラー
$contact-btn-border-color: #354052;

$main-font-color: #354052;
$mincho: 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
$gothic: /*-apple-system, */'Noto Sans Japanese', "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", sans-serif;
$awesome: "Font Awesome 5 Pro";

/* ソースサイズ */
$pc-source-w: 1360;
$sp-source-w: 375;


/* Mixin
--------------------- */

/* Media Queries */
@mixin max-screen($breakpoint) {
	@media screen and (max-width: $breakpoint + 'px') {
		@content;
	}
}

/* Vendor Prefix */
$prefix-arr: '', -moz-, -webkit-;
@mixin add-prefix($name, $value) {
    @each $prefix in $prefix-arr {
        #{$prefix}#{$name}: $value;
    }
}


/* Extend
--------------------- */

/* Clearfix */
%clearfix {
	&::after {
		clear: both;
		content: "";
		display: block;
	}
}

/* Ellipsis */
%ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

/* PseudoTable */
%pseudo-table {
	display: table;
	height: 100%;
	width: 100%;
	> * {
		display: table-cell;
		height: 100%;
		text-align: center;
		vertical-align: middle;
		width: 100%;
	}
}

/* Inner */
%inner {
	margin-left: auto;
	margin-right: auto;
	max-width: 1168px;
	padding-left: 24px;
	padding-right: 24px;
	position: relative;
	width: 100%;
}
